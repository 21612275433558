import './App.css';
import KothRow from "./KothRow";
import {useEffect, useState} from "react";
import kothImage from "./img/koth.png"

export const TIMEZONE = 'America/Buenos_Aires';
export const TIMEOUT_MINUTES = 60;
const koths = [
    {
        name: 'Warp PvP Semanal',
        time: '19:30',
        daysOfWeek: ['Wednesday'],
    },
    {
        name: 'Warp PvP Finde',
        time: '17:30',
        daysOfWeek: ['Saturday', 'Sunday']
    },
];


function App() {
    const [counter, setCounter] = useState(1);

    useEffect(() => {
        setTimeout(() => setCounter(counter + 1), 60000);
    }, [counter]);

    const kothsDisplay = koths.map((koth) =>
        <div key={Math.random()}><KothRow koth={koth}/></div>
    );

    const year = new Date().getFullYear();

    return (
        <div className="App">
            <header className="App-header">
                <h1>Horarios de Koth de Survival</h1>
                {kothsDisplay}
                <img src={kothImage} alt="KOTH de Survival" className="big-image" />
                <footer>
                    <a
                        className="App-link"
                        href="https://factions.iminecrafting.com"
                        rel="next"
                    >
                        factions.iMinecrafting.com
                    </a>
                    <p><small>© iMinecrafting {year}</small></p>
                </footer>
            </header>
        </div>
    );
}

export default App;
