import { DateTime, Duration } from 'luxon';
import './KothRow.css';
import { TIMEZONE, TIMEOUT_MINUTES } from './App';

function KothRow({ koth }) {
    let dt = DateTime.fromISO(koth.time, { zone: TIMEZONE, locale: 'en' });
    const dtPlusTimeout = dt.plus(Duration.fromObject({ minutes: TIMEOUT_MINUTES }));

    if (koth.daysOfWeek?.length > 0) {
        // If today.
        if (koth.daysOfWeek.includes(dt.weekdayLong)) {
            // If already finished.
            const nowDt = DateTime.fromISO(DateTime.now().toString(), { zone: TIMEZONE, locale: 'en' });
            if (dtPlusTimeout.toUnixInteger() - nowDt.toUnixInteger() < 0) {
                dt = dt.plus(Duration.fromObject({ days: 1 }));
            }
        }
        // Find the next day.
        let c = 8; // Just in case, limit iterations.
        while (c > 0 && !koth.daysOfWeek.includes(dt.weekdayLong)) {
            dt = dt.plus(Duration.fromObject({ days: 1 }));
            c--;
        }
    }

    const localDt = DateTime.fromISO(dt.toString(), { zone: 'system', locale: 'en' });

    const pendingMinutes = dt.diffNow('minutes').toObject().minutes;
    const timeLeft = minutesToFancy(pendingMinutes);

    const zoneName = localDt.zoneName.split('/').slice(-1)[0].replace('_', ' ');

    return (
        <div className="row">
            <div>
                ● <strong>{koth.name}</strong> →{' '}
                {/*a las {localDt.toLocaleString({...DateTime.TIME_WITH_SHORT_OFFSET})}{' '}*/}
                <em>en {timeLeft}</em>.<br />
                <div className="description">
                    {daysOfWeekToString(koth.daysOfWeek)
                        .join(', ')
                        .replace(/,([^,]*)$/, ' y $1')}{' '}
                    a las {localDt.toFormat('HH:mm')} ({zoneName})
                </div>
            </div>
        </div>
    );
}

function daysOfWeekToString(daysOfWeek) {
    return daysOfWeek.map((dayOfWeek) => {
        switch (dayOfWeek) {
            case 'Monday':
                return 'lunes';
            case 'Tuesday':
                return 'martes';
            case 'Wednesday':
                return 'miércoles';
            case 'Thursday':
                return 'jueves';
            case 'Friday':
                return 'viernes';
            case 'Saturday':
                return 'sábado';
            case 'Sunday':
                return 'domingo';
            default:
                return '';
        }
    });
}

// https://stackoverflow.com/a/67190154
function minutesToObject(pendingMinutes) {
    const days = Math.floor(pendingMinutes / 1440);
    const hours = Math.floor((pendingMinutes % 1440) / 60);
    const minutes = Math.floor(pendingMinutes % 60);

    return { days, hours, minutes };
}

function minutesToFancy(pendingMinutes) {
    const { days, hours, minutes } = minutesToObject(pendingMinutes);

    const parts = [];

    if (pendingMinutes > 0) {
        if (days !== 0) {
            parts.push(`${days} ` + (days === 1 ? 'día' : 'días'));
        }
        if (hours !== 0) {
            parts.push(`${hours} ` + (hours === 1 ? 'hora' : 'horas'));
        }
        parts.push(`${minutes} ` + (minutes === 1 ? 'minuto' : 'minutos'));
    } else if (pendingMinutes > -28) {
        parts.push('progreso; ahora');
    } else if (pendingMinutes > -TIMEOUT_MINUTES) {
        parts.push('progreso; finalizando');
    }
    return parts.join(', ').replace(/,([^,]*)$/, ' y $1');
}

export default KothRow;
